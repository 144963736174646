<template>
  <div class="bind-container">
    <LoginHeader />
    <div class="page-wrap">
      <div class="page-content-wrap">
        <div class="bind-form">
          <div class="title">绑定鹏隆账号!</div>
          <div class="avatar">
            <img :src="thirdPartyInfo.tavatar" alt="">
          </div>
          <div class="welcome">HI，{{thirdPartyInfo.tnickname}}，欢迎来到鹏隆</div>
          <div class="form-content">
            <div class="form-item">
              <div class="label"><span>*</span>手机号码：</div>
              <input v-model="mobile" autocomplete="false" type="text" placeholder="请输入手机号码">
            </div>
            <div class="form-item">
              <div class="label"><span>*</span>密码：</div>
              <input v-model="password" autocomplete="false" type="password" placeholder="请输入密码">
            </div>
          </div>
          <div @click="bindHandle" class="bind-btn">立即绑定</div>
          <div class="tips">还没有鹏隆账号？<router-link to="/regist">请注册</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginHeader from '@/components/header/header-login.vue'
export default {
  components: {
    LoginHeader
  },
  data () {
    return {
      thirdparty_id: null,
      thirdparty: '',
      mobile: '',
      password: '',
      thirdPartyInfo: {}
    }
  },
  created () {
    this.thirdparty_id = this.$route.query.thirdparty_id
    this.thirdparty = this.$route.query.thirdparty
    this.getThirdpartyInfo()
  },
  methods: {
    // 绑定
    async bindHandle () {
      if (!this.checktel(this.mobile)) {
        return false
      }
      if (!this.password) {
        this.$message({
          type: 'error',
          message: '密码不能为空',
          duration: 1500
        })
        return false
      }
      const params = {
        thirdparty_id: this.thirdparty_id,
        thirdparty: this.thirdparty,
        mobile: this.mobile,
        password: this.password
      }
      const res = await this.$http.post('/api/thirdparty-bind', params)
      console.log(res)
      localStorage.setItem('token', res.token)
      this.$store.dispatch('setUserInfo')
      this.$router.push({ path: '/' })
    },
    // 手机号校验
    checktel (val){
      if (val=='') {
        this.$message.error('手机号不能为空')
        return false
      }
      let myreg = /^[1][2,3,4,5,7,6,8,9][0-9]{9}$/
      if (!myreg.test(val)) {
        this.$message.error('手机号格式不正确')
        return false
      }
      return true
    },
    // 获取第三方登录用户信息
    async getThirdpartyInfo () {
      const res = await this.$http.get('/api/thirdparty-info', { thirdparty_id: this.thirdparty_id })
      console.log(res)
      this.thirdPartyInfo = res
    }
  }
}
</script>
<style lang="scss" scoped>
.bind-container {
  .page-wrap {
    background-image: url('../../assets/images/common/regist_bg.png');
    background-size: cover;
    background-position: center center;
    padding: 40px 0;
    .bind-form {
      margin: 0 auto;
      width: 450px;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 40px 30px;
      font-size: 16px;
      .title {
        text-align: center;
        color: #3385FF;
        font-size: 24px;
      }
      .avatar {
        width: 100px;
        height: 100px;
        // border: 1px solid red;
        border-radius: 5px;
        margin: 30px auto 0;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .welcome {
        text-align: center;
        margin-top: 20px;
      }
      .form-content {
        margin-top: 30px;
        .form-item {
          display: flex;
          align-items: center;
          justify-content: space-around;
          .label {
            text-align: right;
            min-width: 87px;
            span {
              color: red;
            }
          }
          input {
            flex: 1;
            line-height: 50px;
            outline: none;
            border: 0 none;
            border-bottom: 1px solid #e6e6e6;;
          }
        }
      }
      .bind-btn {
        width: 100%;
        height: 45px;
        background-color: #3385FF;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        margin-top: 30px;
      }
      .tips {
        text-align: center;
        margin-top: 20px;
        a {
          color: #3385FF;
          text-decoration: none
        }
      }
    }
  }
}
</style>
